var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-1/2 md:w-1/3 xl:w-1/6" },
        [
          _c("statistics-card-line", {
            staticClass: "mb-base",
            attrs: {
              hideChart: "",
              icon: "EyeIcon",
              statistic: "36.9k",
              statisticTitle: "Views",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/2 md:w-1/3 xl:w-1/6" },
        [
          _c("statistics-card-line", {
            staticClass: "mb-base",
            attrs: {
              hideChart: "",
              icon: "MessageSquareIcon",
              statisticTitle: "Comments",
              statistic: "12k",
              color: "success",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/2 md:w-1/3 xl:w-1/6" },
        [
          _c("statistics-card-line", {
            staticClass: "mb-base",
            attrs: {
              hideChart: "",
              icon: "ShoppingBagIcon",
              statistic: "978",
              statisticTitle: "Orders",
              color: "warning",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/2 md:w-1/3 xl:w-1/6" },
        [
          _c("statistics-card-line", {
            staticClass: "mb-base",
            attrs: {
              hideChart: "",
              icon: "HeartIcon",
              statistic: "26.8k",
              statisticTitle: "Favorited",
              color: "danger",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/2 md:w-1/3 xl:w-1/6" },
        [
          _c("statistics-card-line", {
            staticClass: "mb-base",
            attrs: {
              hideChart: "",
              icon: "SmileIcon",
              statistic: "689",
              statisticTitle: "Reviews",
              color: "success",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/2 md:w-1/3 xl:w-1/6" },
        [
          _c("statistics-card-line", {
            staticClass: "mb-base",
            attrs: {
              hideChart: "",
              icon: "TruckIcon",
              statistic: "2",
              statisticTitle: "Returns",
              color: "warning",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" },
        [
          _c("statistics-card-line", {
            staticClass: "mb-base",
            attrs: {
              hideChart: "",
              icon: "CpuIcon",
              "icon-right": "",
              statistic: "86%",
              statisticTitle: "CPU Usage",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" },
        [
          _c("statistics-card-line", {
            staticClass: "mb-base",
            attrs: {
              hideChart: "",
              icon: "ServerIcon",
              "icon-right": "",
              statistic: "1.2gb",
              statisticTitle: "Memory Usage",
              color: "success",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" },
        [
          _c("statistics-card-line", {
            staticClass: "mb-base",
            attrs: {
              hideChart: "",
              icon: "ActivityIcon",
              "icon-right": "",
              statistic: "0.1%",
              statisticTitle: "Downtime Ratio",
              color: "danger",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" },
        [
          _c("statistics-card-line", {
            staticClass: "mb-base",
            attrs: {
              hideChart: "",
              icon: "AlertOctagonIcon",
              "icon-right": "",
              statistic: "13",
              statisticTitle: "Issues Found",
              color: "warning",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" },
        [
          _vm.subscribersGained.analyticsData
            ? _c("statistics-card-line", {
                staticClass: "mb-base",
                attrs: {
                  icon: "UsersIcon",
                  statistic: _vm._f("k_formatter")(
                    _vm.subscribersGained.analyticsData.subscribers
                  ),
                  statisticTitle: "Subscribers Gained",
                  chartData: _vm.subscribersGained.series,
                  type: "area",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" },
        [
          _vm.revenueGenerated.analyticsData
            ? _c("statistics-card-line", {
                staticClass: "mb-base",
                attrs: {
                  icon: "DollarSignIcon",
                  statistic: _vm._f("k_formatter")(
                    _vm.revenueGenerated.analyticsData.revenue
                  ),
                  statisticTitle: "Revenue Generated",
                  chartData: _vm.revenueGenerated.series,
                  color: "success",
                  type: "area",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" },
        [
          _vm.quarterlySales.analyticsData
            ? _c("statistics-card-line", {
                staticClass: "mb-base",
                attrs: {
                  icon: "ShoppingCartIcon",
                  statistic: _vm.quarterlySales.analyticsData.sales,
                  statisticTitle: "Quarterly Sales",
                  chartData: _vm.quarterlySales.series,
                  color: "danger",
                  type: "area",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" },
        [
          _vm.ordersRecevied.analyticsData
            ? _c("statistics-card-line", {
                staticClass: "mb-base",
                attrs: {
                  icon: "ShoppingBagIcon",
                  statistic: _vm._f("k_formatter")(
                    _vm.ordersRecevied.analyticsData.orders
                  ),
                  statisticTitle: "Orders Received",
                  chartData: _vm.ordersRecevied.series,
                  color: "warning",
                  type: "area",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
        [
          _c("statistics-card-line", {
            staticClass: "md:mb-0 mb-base",
            attrs: {
              icon: "MonitorIcon",
              "icon-right": "",
              statistic: "78.9k",
              statisticTitle: "Site Traffic",
              chartData: _vm.siteTraffic.series,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
        [
          _c("statistics-card-line", {
            staticClass: "md:mb-0 mb-base",
            attrs: {
              icon: "UserCheckIcon",
              "icon-right": "",
              statistic: "659.8k",
              statisticTitle: "Active Users",
              chartData: _vm.activeUsers.series,
              color: "success",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3" },
        [
          _c("statistics-card-line", {
            attrs: {
              icon: "MailIcon",
              "icon-right": "",
              statistic: "28.7k",
              statisticTitle: "Newsletter",
              chartData: _vm.newsletter.series,
              color: "warning",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }